<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="联系电话" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入联系电话" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="审核状态" prop="status">
                <a-select placeholder="审核状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.user_cancel_status" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        v-hasPermi="['biz:cancel:export']"-->
        <a-button type="primary" @click="handleExport" >
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
<!--      <create-form-->
<!--        ref="createForm"-->
<!--        :statusOptions="dict.type.user_cancel_status"-->
<!--        @ok="getList"-->
<!--      />-->
      <!-- 查看 -->

      <approve-model ref="approveModel" @close="auditClose"></approve-model>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="examineTime" slot-scope="text, record">
          {{ parseTime(record.examineTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          v-hasPermi="['biz:cancel:edit']"-->
          <a-divider v-if='record.status == 0' type="vertical"  />
<!--          v-hasPermi="['biz:cancel:edit']"-->
          <a v-if='record.status == 0' @click="$refs.approveModel.audit(record)" >
            <a-icon type="edit" />审核
          </a>
<!--          v-hasPermi="['biz:cancel:remove']" -->
          <a-divider type="vertical" />
<!--          v-hasPermi="['biz:cancel:remove']"-->
          <a @click="handleDelete(record)" >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageCancel,listCancel, delCancel } from '@/api/biz/cancel'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ApproveModel from "./modules/ApproveModel.vue";
export default {
  name: 'Cancel',
  components: {
    ApproveModel,
    CreateForm,
  },
  mixins: [tableMixin],
  dicts: ['user_cancel_status'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用户昵称',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '联系电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注销原因',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 0){
              return "审核中"
            }else if(value == 2){
              return "审核成功"
            }
            return "审核失败"
          }
        },
        {
          title: '审核说明',
          dataIndex: 'failReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注销时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户注销列表 */
    getList () {
      this.loading = true
      pageCancel(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCancel(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/user-cancel/export', {
            ...that.queryParam
          }, `用户注销_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    auditClose() {
      this.getList();
    }
  }
}
</script>
