<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='用户id, 外键{zb_user.id}' prop='userId'
      >
        <a-input v-model='form.userId' placeholder='请输入用户id, 外键{zb_user.id}' />
      </a-form-model-item>
      <a-form-model-item label='类型, 1-多账号, 释放手机号, 2-不想使用了 3-其他, 默认-3' prop='type'
      >
      </a-form-model-item>
      <a-form-model-item label='注销原因' prop='reason'
      >
        <a-input v-model='form.reason' placeholder='请输入注销原因' />
      </a-form-model-item>
      <a-form-model-item label='状态: 0-审核中, 1-审核成功, 2-审核失败, 默认-0' prop='status'
      >
      </a-form-model-item>
      <a-form-model-item label='拒绝原因' prop='refuseContent'
      >
        <editor v-model='form.refuseContent' />
      </a-form-model-item>
      <a-form-model-item label='备注' prop='remark'
      >
        <a-input v-model='form.remark' placeholder='请输入内容' type='textarea' allow-clear />
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCancel, addCancel, updateCancel } from '@/api/user/cancel'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {}
  ,
  components: {
    Editor
  }
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        reason: null,

        status: 0
        ,

        refuseContent: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          {
            required: true, message: '用户id, 外键{zb_user.id}不能为空', trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '类型, 1-多账号, 释放手机号, 2-不想使用了 3-其他, 默认-3不能为空',
            trigger: 'change'
          }
        ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        reason: null,
        status: 0
        ,
        refuseContent: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCancel({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCancel(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCancel(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
