import request from '@/utils/request'


// 查询用户注销账户申请列表
export function listCancel(query) {
    return request({
        url: '/user/user-cancel/list',
        method: 'get',
        params: query
    })
}

// 查询用户注销账户申请分页
export function pageCancel(query) {
    return request({
        url: '/user/user-cancel/page',
        method: 'get',
        params: query
    })
}

// 查询用户注销账户申请详细
export function getCancel(data) {
    return request({
        url: '/user/user-cancel/detail',
        method: 'get',
        params: data
    })
}

// 新增用户注销账户申请
export function addCancel(data) {
    return request({
        url: '/user/user-cancel/add',
        method: 'post',
        data: data
    })
}

// 修改用户注销账户申请
export function updateCancel(data) {
    return request({
        url: '/user/user-cancel/edit',
        method: 'post',
        data: data
    })
}
// 审核用户注销账户申请
export function approveCancel(data) {
    return request({
        url: '/biz/user-cancel/approve',
        method: 'post',
        data: data
    })
}
// 删除用户注销账户申请
export function delCancel(data) {
    return request({
        url: '/user/user-cancel/delete',
        method: 'post',
        data: data
    })
}
