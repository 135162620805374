<template>
  <a-modal title='审核' :width='800' :visible='visible' :confirmLoading='confirmLoading' @ok='handleOk' @cancel='close'
    cancelText='关闭'>

    <a-spin :spinning='confirmLoading'>
      <a-form>
        <a-form-item label="审核结果" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <api-select placeholder='请选择审核结果' v-model="formData.status" :option="statusOptions" />
        </a-form-item>

        <a-form-item v-show="formData.status == 1" label="审核结果" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea :rows='3' placeholder='请输入拒绝原因(80字以内)' v-model="formData.failReason"  :maxlength="80"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import ApiSelect from '@/components/crud/form/ApiSelect.vue'
import { approveCancel } from '@/api/user/cancel'

export default {
  name: 'CrudForm',
  components: {
    ApiSelect
  },
  props: {
    statusOptions: {
      type: Array,
      default() {
        return [{ id: 2, name: '审核通过' }, { id: 1, name: '审核驳回' }];
      }
    }
  },
  data() {
    return {
      formData: {
        id: '',
        userId: null,
        status: null,
        failReason:null
      },           // 表单数据
      visible: false,         // 弹窗显示控制
      confirmLoading: false,  // 提交后加载, 防止短时间内重复提交

      labelCol: { xs: { span: 24 }, sm: { span: 5 } },    // 表单项标签宽度配置
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, // 表单项内容宽度配置
    }
  },
  methods: {
    /**
     * 外部方法, 外部调用此方法 打开编辑表单
     * @param {*} record 数据
     */
    audit(record) {
      this.formData.id = record.id;
      this.formData.userId = record.userId;
      this.formData.status = null;
      this.formData.failReason = '';
      this.confirmLoading = false;
      this.visible = true;
    },

    /**
     * 关闭表单, 触发 close 事件
     */
    close() {
      this.$emit('close')
      this.visible = false
    },
    /**
     * 提交
     */
    handleOk() {
      this.confirmLoading = true;
      if (!this.formData.status) {
        this.$message.warn("请选择审核结果");
        this.confirmLoading = false;
        return;
      }
      if (this.formData.status == 1 && !this.formData.failReason) {
        this.$message.warn("请输入拒绝原因");
        this.confirmLoading = false;
        return;
      }
      approveCancel(this.formData).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.close()
          this.$emit('ok');
        } else {
          this.$message.warning(res.message)
        }

      }).finally(() => {
        this.submitLoading = false
      })
    },
  }
}
</script>

<style lang='less' scoped></style>